import TerryLogo from "../../assets/images/icons/t-logo.png";
import ArrowImg from "../../assets/images/icons/Arrow.png";

const TerryHomePage = () => {
    return <>
        <div className="bg-[#FFFAEE] min-h-[100vh] w-full fixed">
            <div className="flex items-center w-full justify-center gap-[12px] mt-[35px]">
                <img src={TerryLogo}/>
                <span className="text-black text-[28px] font-bold">Terry Health</span>
            </div>
            <div className="relative flex justify-center items-start min-h-screen px-4 mt-[136px]">
                <div className="w-full flex justify-center flex-col gap-[23px]">
                    <div className="bg-white self-center">
                        <div className="bg-[#C0B0FF] h-[13px] w-[620px] rounded-t-2xl"/>
                        <div className="flex pb-[35px] flex-col self-center w-[620px] gap-[24px] mt-[23px]">
                            <div className="flex flex-col gap-[16px] self-center justify-center w-[469px]">
                                <p className="self-center font-semibold text-[32px]">Hey, I'm Terry!</p>
                                <p className="self-center font-normal text-[16px] text-[#696969] text-center leading-5">I’m
                                    here to simplify your documentation process, giving you more time to focus on
                                    meaningful work!</p>
                            </div>
                            <button className="py-[16px] px-[32px] self-center bg-[#C0B0FF] rounded-md cursor-pointer" onClick={()=> window.open("https://secure.therapservices.net/auth/login?successUrl=https%3A%2F%2Fsecure.therapservices.net%3A443%2Fma%2Fnewfpage%2FswitchFirstPage")}>
                                <span>Get Started</span>
                            </button>
                        </div>
                    </div>
                    <p className="self-center text-center leading-5 text-[16px] font-normal">Having Trouble? Contact
                        our <a className="text-[#C0B0FF] cursor-pointer" href="https://www.google.com" target="_blank">Customer Support Team </a>with all
                        inquiries.</p>
                </div>

                <div className="flex absolute transform rotate-12 lg:block flex-shrink-0" style={{ top: 'calc(18vh)', left: 'calc(52% + 310px)' }}>
                    <img src={ArrowImg} alt="Arrow img"/>
                </div>
            </div>
        </div>
    </>
}

export default TerryHomePage;